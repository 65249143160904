import React, { useEffect, useRef, useState } from "react";
import OrderCard from "./OrderCard";

const OrderHistory = ({
  openOrders,
  setDeleteOrderId,
  closeOrders,
  setActiveTab,
  createNewOrder,
  handleEditIcon,
  setMessageType,
  setShowPopUp,
  setPopUpMessage,
  labels,
  isLoading,
  sendEventToParentIframe,
  formType
}) => {
  const containerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const element = containerRef.current;
        const isOverflowing = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        setHasOverflow(isOverflowing);
      }
    };
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);



  return (
    <div className="order-history-container" ref={containerRef} style={{ paddingRight: hasOverflow ? '15px' : '25px'}}>
      {openOrders?.length>0 && <div style={{ fontWeight: "bold", fontSize: "15px", marginBottom:'8px', marginTop:'2px' }}>{labels.open_order}</div>}
      <div>
        {openOrders.map((order, index) => {
          return (
            <OrderCard
              sendEventToParentIframe={sendEventToParentIframe}
              labels={labels}
              key={index}
              setDeleteOrderId={setDeleteOrderId}
              type={"open"}
              setPopUpMessage={setPopUpMessage}
              setShowPopUp={setShowPopUp}
              orderData={order}
              handleEditIcon={handleEditIcon}
              setMessageType={setMessageType}
              formType={formType}
            />
          );
        })}

        {closeOrders?.length>0 && <div
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginBottom: "10px",
            marginTop: "22px",
            paddingTop: "12px",
            borderTop: "1px solid #c5c1c1",
          }}
        >
          {labels.close_order}
        </div>}
        {closeOrders.map((order, index) => {
          return (
            <OrderCard
              labels={labels}
              key={index}
              type={"close"}
              orderData={order}
              formType={formType}
            />
          );
        })}
      </div>
      {!isLoading && openOrders?.length === 0 && closeOrders?.length === 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {labels.no_order_history}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
