export const storeConstants = {
  SET_AUTHENTICATION: "@@AUTHENTICATION/SET_AUTHENTICATION",
  LOGOUT: "@@AUTHENTICATION/LOGOUT",
  USERNAME: "@@USER_MANAGEMENT/USERNAME",
  FETCH_USERLIST: "@@USER_MANAGEMENT/FETCH_USERLIST",
  FETCH_PHARMACYUSERSLIST: "@@USER_MANAGEMENT/FETCH_PHARMACYUSERSLIST",
  FETCH_CLIENTPOSACCOUNTLIST: "@@USER_MANAGEMENT/FETCH_CLIENTPOSACCOUNTLIST",
  FETCH_SEGMENTATIONLIST: "@@USER_MANAGEMENT/FETCH_SEGMENTATIONLIST",
  FETCH_PREDEFINEDOFFERLIST: "@@USER_MANAGEMENT/FETCH_PREDEFINEDOFFERLIST",
  FETCH_MANUAL_OFFERLIST: "@@USER_MANAGEMENT/FETCH_MANUAL_OFFERLIST",
  FETCH_BONUS_OFFERLIST: "@@USER_MANAGEMENT/FETCH_BONUS_OFFERLIST",
  FETCH_STANDARDSEGMENTOFFERLIST:
    "@@USER_MANAGEMENT/FETCH_STANDARDSEGMENTOFFERLIST",
  FETCH_VALIDOFFERLIST: "@@USER_MANAGEMENT/FETCH_VALIDOFFERLIST",
  FETCH_COMMERCIALCONDITIONLIST:
    "@@USER_MANAGEMENT/FETCH_COMMERCIALCONDITIONLIST",
  FETCH_WHOLESALERLIST: "@@USER_MANAGEMENT/FETCH_WHOLESALERLISTLIST",
  FETCH_ACCOUNTSLIST: "@@USER_MANAGEMENT/FETCH_ACCOUNTSLIST",
  FETCH_OFFERPERIODLIST: "@@USER_MANAGEMENT/FETCH_OFFERPERIODLIST",
  FETCH_ORDERLIST: "@@USER_MANAGEMENT/FETCH_ORDERLIST",
  THEME: "@@THEME/THEME",
  GET_LANGUAGE: "@@LANGUAGE/GET_LANGUAGE",
  SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",
  SET_LANGUAGE: "SET_LANGUAGE",
  PAGE_NUMBER: "@@PAGE_NUMBER",
  PAGE_NUMBER_ROLE_LIST: "@@PAGE_NUMBER_ROLE_LIST",
  PAGE_NUMBER_USER: "@@PAGE_NUMBER_USER",
  ROLE_LIST: "/ROLELIST/ROLELIST",
  NOTIFICATION_COUNT: "/NOTIFICATION/NOTIFICATION_COUNT",
  FETCH_EXT_WHOLESLER_MAPPING_LIST: "@@USER_MANAGEMENT/FETCH_EXT_WHOLESLER_MAPPING_LIST",
  FETCH_FEEDBACKLIST: "@@USER_MANAGEMENT/FETCH_FEEDBACKLIST",
  FETCH_EVENTCONFIGLIST: "@@USER_MANAGEMENT/FETCH_EVENTCONFIGLIST",
  EVENT_HISTORYLIST: "@@USER_MANAGEMENT/EVENT_HISTORY_LIST",
  FETCH_LOC_ORDERLIST: "@@USER_MANAGEMENT/FETCH_LOC_ORDERLIST",

  TEMP_ORDER_LIST: "@@USER_MANAGEMENT/TEMP_ORDER_LIST",
  FETCH_LINK_TABLE:
    "@@USER_MANAGEMENT/FETCH_LINK_TABLE",
};
