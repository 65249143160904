
const devUrl = process.env.REACT_APP_ENV === 'DEV';
const stageUrl = process.env.REACT_APP_ENV === 'STAGE';

console.log(process.env)

const Config = {
    // BaseURL: 'http://localhost:8097/', //dev
    //BaseURL: 'https://stagingadmin.gskassist.com/yelo', //stage
    BaseURL: devUrl ? `${window.location.protocol}//${window.location.hostname}:8097` : 
             stageUrl? `${window.location.protocol}//${window.location.hostname}/yelo`: 
             `${window.location.protocol}//${window.location.hostname}/`,
    DeleteDelay: 3000,
    DefaultLimit: 10,
    REACT_APP_CLIENT: 'padmin',
    REACT_APP_SECRET: 'op#123',
};

export default Config