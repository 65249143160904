import PageLoader from "../../library/PageLoader";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import ErrorIcon from "./SVGIcon/ErrorIcon.svg";
import SuccessIcon from "./SVGIcon/successIcon.svg";
import axios from "axios";
import Config from "../../core/Config";
import Select from "react-select";
import OrderHistory from "./OrderHistory";
import { downloadExportFile } from "../../library/utilities";
import { Tooltip } from "react-tooltip";
import WhiteDeleteIcon from "./SVGIcon/WhiteDeleteIcon.svg"
const formType = {
  type1: "type1",
  type2: "type2",
};

const LocCheckout = ({ loc, type, labels, allowSkuIdentifierSearch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWholesaler, setSelectedWholesaler] = useState("");
  const [wholesalers, setWholeSalers] = useState([]);
  const [wholesalerError, setWholesalerError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [botEventData, setBotEventData] = useState({});
  const [orderData, setOrderData] = useState([]);
  const [originalSkuData, setOriginalSkuData] = useState([]);
  const [allSkuData, setAllSkuData] = useState([]);
  const [initialSkuData, setInitialSkuData] = useState([]);
  const [orderSummary, setOrderSummary] = useState({
    totalUnits: 0,
    grossValue: 0,
    absoluteDiscount: 0,
    totalInvoise: 0,
  });
  const [messageType, setMessageType] = useState("");
  const [popUpMessage, setPopUpMessage] = useState("");
  const [thanksMessage, setThanksMessage] = useState("");
  const [orderHistory, setOrderHistory] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [availableWholesaler, setAvailableWholesaler] = useState([]);
  const [showCalenderValue, setShowCalenderValue] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("DD-MM-YYYY")
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const [openCalender, setOpenCalender] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [dateAvailble, setDateAvailable] = useState(true);
  const [options, setOptions] = useState([]);
  const [closeOrder, setCloseOrder] = useState([]);
  const [brandBgColor, setBrandBgColor] = useState([]);
  const [editOrderData, setEditOrderData] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const [isFarmaciesChangeCalled, setIsFarmaciesChangeCalled] = useState(false);
  const [triadCloseOrderDates, setTriadCloseOrderDates] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderFlow = searchParams.get("orderflow");
  const userType = searchParams.get("usertype");
  const [activeTab, setActiveTab] = useState(
    orderFlow?.toLowerCase() === "edit" ? 2 : 1
  );
  const [productLink, setProductLink] = useState([]);
  const borderColor = "grey";
  const todayDate = new Date().toISOString().split("T")[0];
  const phoneNo = location.search.substring(1).split("&")[0].substring(3);
  const [cancelButtonsMessage,SetCancelButtonMessage] = useState({});
  const [sendHistoryTabEvent,setSendHistoryTabEvent] = useState(true);
  const [isReloaded,setIsReloaded] = useState(false);
  const inputRef = useRef(null);

  const sendEventToParentIframe = (eventName, data) => {
    window.parent.postMessage(
      JSON.stringify({
        event_code: "custom-react-event",
        data: {
          eventName: eventName,
          eventData: data,
        },
      }),
      "*"
    );
  };

  const getIrecmLink = (skuIdentifier) => {
    const findSku = productLink.find(
      (item) => item.skuIdentifier == skuIdentifier
    );
    if (findSku) {
      return (
        <a
          tabIndex={-1}
          target="_blank"
          href={findSku.skuLink || findSku.brandLink}
          rel="noreferrer"
          style={{
            color: "#0e72ba",
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "10px",
            fontWeight: "bold",
          }}
        >
          IERCM
        </a>
      );
    } else {
      return "";
    }
  };

  const getFTLink = (skuIdentifier) => {
    const findSku = productLink.find(
      (item) => item.skuIdentifier == skuIdentifier
    );
    if (findSku) {
      return (
        <a
          tabIndex={-1}
          rel="noreferrer"
          target="blank"
          href={findSku.skuLink || findSku.brandLink}
          className="gsk-font"
          style={{
            background: "rgb(20, 146, 20)",
            fontWeight: "bold",
            color: "white",
            padding: "2px",
            borderRadius: "100%",
            height: "20px",
            width: "20px",
            fontSize: "11px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          FT
        </a>
      );
    } else {
      return "";
    }
  };

  const getSkuData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${
          Config.BaseURL
        }/authenticated/v2/pharmacy-users/offers?phoneNumber=${encodeURIComponent(
          phoneNo
        )}&loc=${loc}&noOfPharmacies=${botEventData.noOfPharmacies}`,
        {
          headers: {
            Authorization: `Bearer ${botEventData.token}`,
          },
        }
      );
      setIsLoading(false);
      if (data?.clubbedOffers) {
        let updatedClubbedOffers = [];
        for (let skuData of data.clubbedOffers) {
          if (skuData.skuIdentifier && skuData.skuPrice) {
            updatedClubbedOffers.push(skuData);
          }
        }
        setAllSkuData(updatedClubbedOffers);
        setOriginalSkuData(updatedClubbedOffers);
        setInitialSkuData(data.clubbedOffers);

        if (data?.orderHistory) {
          const sortedOrderHistory = data.orderHistory.sort((a, b) => {
            const dateA = new Date(a.orderedDateString);
            const dateB = new Date(b.orderedDateString);
            return dateB - dateA;
          });

          const currentPeriodHistory = sortedOrderHistory.filter(
            (item) =>
              item.currentPeriod &&
              item.internalOrderStatus === "OPEN" &&
              item.status !== "NÃO CONFIRMADO" &&
              item.status !== "NOT_CONFIRMED" &&
              item.status !== "NO CONFIRMADO"
          );

          setOrderHistory(currentPeriodHistory);
          const closeOrderPeriodHistory = sortedOrderHistory.filter(
            (item) =>
              item.currentPeriod && item.internalOrderStatus === "CLOSED"
          );
          setCloseOrder(closeOrderPeriodHistory);
        }
      }
      if (data?.accounts) {
        const filteredAccounts = data.accounts.filter(
          (account) =>
            account.customerServiceAccountStatus === "PENDING_VERIFICATION" ||
            account.customerServiceAccountStatus === "VERIFIED"
        );
        setWholeSalers(filteredAccounts);
      }

      if (data?.productLink) {
        setProductLink(data.productLink);
      }
    } catch (error) {
      setIsLoading(false);
      setAllSkuData([]);
      setOriginalSkuData([]);
      setInitialSkuData([]);
    }
  };

  useEffect(() => {
    if (botEventData?.token) {
      if (phoneNo === "") {
        alert("Phone number is required");
        return;
      }
      getSkuData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botEventData?.token]);

  useEffect(() => {
    if (wholesalers.length > 0) {
      const optionList = wholesalers.map((item, index) => {
        return {
          label: `${item.wholesalerName} - ${item.accountCode}`,
          value: index,
        };
      });
      setOptions(optionList);
    }
  }, [wholesalers]);

  const handleConfirmPopUp = (e) => {
    e.preventDefault();
    if (!dateAvailble && !editOrderData) {
      return;
    }
    if (
      botEventData.noOfPharmacies === "0" ||
      botEventData.noOfPharmacies === ""
    ) {
      setShowPopUp(true);
      setMessageType("error");
      setPopUpMessage(labels.no_of_phar_error);
      return;
    }
    if (selectedWholesaler === "") {
      setWholesalerError(true);
      return;
    }
    if (selectedDate === "") {
      setDateError(true);
      return;
    }
    if (orderData.length === 0) {
      setShowPopUp(true);
      setMessageType("error");
      setPopUpMessage(labels.unit_select_error);
      return;
    }
    setMessageType("confirmMessage");
    setPopUpMessage(labels.order_confirm_message);
    setShowPopUp(true);
  };

  const handleCancelPopUp = () => {
    setShowPopUp(true);
    SetCancelButtonMessage({
      yesButton: editOrderData ? labels.drop_changes_yes : labels.cancel_order,
      noButton: editOrderData ? labels.drop_changes_no: labels.keep_order
    })
    setPopUpMessage(editOrderData ? labels.drop_changes : labels.cancel_message);
    setMessageType("cancelMessage");
  };

  const cancelForm = () => {
    document.getElementById("filter-input").value = "";
    setShowPopUp(false);
    setIsLoading(true);
    sendEventToParentIframe("checkout-form-canceled", {});
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let queryStringObj = {};
      location.search
        .substring(1)
        .split("&")
        .forEach((i) => {
          let a = i.split("=");
          queryStringObj = {
            ...queryStringObj,
            [a[0]]: a[1],
          };
        });

      const wholeSalerValue = wholesalers[selectedWholesaler];
      let orderStatus;

      if (
        wholeSalerValue?.customerServiceAccountStatus === "PENDING_VERIFICATION"
      ) {
        orderStatus = "CONDITIONAL";
      } else if (showCalenderValue === todayDate) {
        orderStatus = "IN_PROCESS";
      } else {
        orderStatus = "AWAITING_DATE";
      }

      const updatedOrderData = orderData.map((orderItem) => {
        const { price, ...rest } = orderItem;
        return {
          ...rest,
          accountCode: wholeSalerValue.accountCode,
          orderDate: showCalenderValue,
          externalOrderStatus: orderStatus,
          noOfPharmaciesOrder: botEventData.noOfPharmacies,
          orderPlacedBy: queryStringObj?.repPhone
            ? queryStringObj?.repPhone
            : rest.orderPlacedBy,
          orderId: editOrderData ? editOrderData.orderId : -1,
          legalIdCode: wholeSalerValue.legalIdCode,
        };
      });

      let body = {
        orderDetails: updatedOrderData,
        clubbedOffers: initialSkuData,
      };
      const { status } = await axios.post(
        `${Config.BaseURL}/authenticated/orders?email=true`,
        body,
        {
          headers: {
            Authorization: `Bearer ${botEventData.token}`,
          },
        }
      );
      setIsLoading(false);
      console.log("Status::", status);
      setMessageType("success");

      let usernameMessage = labels.order_success_message2.replace(
        "username_here",
        botEventData?.username?.split(" ")[0]
      );

      if (botEventData?.username) {
        setPopUpMessage(usernameMessage);
      }else{
        setPopUpMessage(labels.order_success_message3);
      }
      sendEventToParentIframe("checkout-form-submitted", {
        orderDetails: updatedOrderData,
      });
    } catch (error) {
      setIsLoading(false);
      setPopUpMessage(<span>{labels.api_error1}</span>);
      setMessageType("error");
    }
  };

  const removeSkuOrderData = (skuData) => {
    const updatedOrderData = orderData.filter(
      (data) => data.skuIdentifier !== skuData.skuIdentifier
    );
    setOrderData(updatedOrderData);
  };

  const handleQuantityChange = (
    value,
    skuData,
    pharmaciesUpdated = false,
    orderDataValues = []
  ) => {
    const regex = /^-?\d+$/;
    if (value == 0 || value === "") {
      removeSkuOrderData(skuData);
    } else if (regex.test(value)) {
      value = Math.abs(value);

      if (value > skuData.maxVolume) {
        let maxVolumeError = labels.maxVolumeError.replace(
          "number_of_max_volume",
          skuData.maxVolume
        );
        setPopUpMessage(maxVolumeError);
        setMessageType("error");
        setShowPopUp(true);
        return;
      }

      if (value < 0) {
        return;
      }

      const findExistingSku = orderData?.find(
        (data) => data.skuIdentifier === skuData.skuIdentifier
      );
      let discountValue;

      if (
        skuData.onInvoiceDiscountRanges &&
        skuData.onInvoiceDiscountRanges.length > 0 &&
        type !== formType.type2
      ) {
        for (let i = 0; i < skuData?.onInvoiceDiscountRanges?.length; i++) {
          if (i + 1 === skuData?.onInvoiceDiscountRanges?.length) {
            if (value >= skuData?.onInvoiceDiscountRanges[i].label) {
              discountValue = skuData.onInvoiceDiscountRanges[i].discount;
              break;
            }
          } else {
            if (value >= skuData?.onInvoiceDiscountRanges[i].label) {
              discountValue = skuData.onInvoiceDiscountRanges[i + 1].discount;
            }
          }
        }
      } else {
        discountValue = 0;
      }

      if (!findExistingSku) {
        setOrderData([
          ...orderData,
          {
            loc: loc,
            orderType: "ORDER",
            internalOrderNumber: "",
            internalOrderStatus: "OPEN",
            externalOrderNumber: "",
            externalOrderDate: "",
            brandIdentifier: skuData.brandIdentifier,
            brand: skuData.brand,
            skuIdentifier: skuData.skuIdentifier,
            sku: skuData.sku,
            requestedVolume: parseInt(value),
            invoicedVolume: "",
            onInvoiceDiscount: discountValue ? parseFloat(discountValue) : 0,
            onInvoiceFreeGoods: "",
            onInvoicePaymentTerms: "",
            offInvoiceDiscount: "",
            offInvoiceFreeGoods: "",
            phoneNumber: phoneNo,
            orderPlacedBy: phoneNo,
            price: skuData.skuPrice,
          },
        ]);
      } else {
        if (pharmaciesUpdated) {
          const updatedOrderData = orderDataValues.map((data) => {
            if (data.skuIdentifier === findExistingSku.skuIdentifier) {
              return {
                ...data,
                onInvoiceDiscount: discountValue
                  ? parseFloat(discountValue)
                  : 0,
                requestedVolume: parseInt(value),
              };
            } else {
              return data;
            }
          });
          return updatedOrderData;
        } else {
          const updatedOrderData = orderData.map((data) => {
            if (data.skuIdentifier === findExistingSku.skuIdentifier) {
              return {
                ...data,
                onInvoiceDiscount: discountValue
                  ? parseFloat(discountValue)
                  : 0,
                requestedVolume: parseInt(value),
              };
            } else {
              return data;
            }
          });
          setOrderData(updatedOrderData);
        }
      }
    }
  };

  const handleFilter = (filterValue) => {
    setFilterValue(filterValue);
    if (filterValue === "") {
      setAllSkuData(originalSkuData);
      return;
    }
    const updatedFilerData = originalSkuData.filter((skuData) => {
      let isExist = allowSkuIdentifierSearch ? (skuData?.ean+" - "+skuData?.sku).toLowerCase().includes(filterValue.toLowerCase()) :  skuData?.sku
        ?.toLowerCase()
        .includes(filterValue.toLowerCase());
      if (isExist) {
        return skuData;
      }
    });
    setAllSkuData(updatedFilerData);
  };

  const clearFilter = () => {
    setAllSkuData(originalSkuData);
    document.getElementById("filter-input").value = "";
  };

  const getRequestedVolume = (skuData) => {
    let value = orderData.find(
      (data) => data.skuIdentifier === skuData.skuIdentifier
    );
    return value?.requestedVolume ? value?.requestedVolume : "";
  };

  const getDiscountPercent = (skuData) => {
    let value = orderData.find(
      (data) => data.skuIdentifier === skuData.skuIdentifier
    );
    return value?.onInvoiceDiscount ? `${value?.onInvoiceDiscount}%` : "-";
  };

  const getBrandBackgroundColor = (skuData, isPriceCard) => {
    let findColor = brandBgColor.find(
      (item) => item.brandIdentifier === skuData.brandIdentifier
    );
    if (isPriceCard && findColor) {
      if (findColor.color === "white") {
        return "#F2F2F2";
      } else {
        return "#FDF0E7";
      }
    }
    return findColor ? findColor.color : "";
  };

  useEffect(() => {
    if (allSkuData.length > 0) {
      let color1 = "white";
      let color2 = "#FAE5D6";
      let previousBrand;
      let updatedBrandBgColor = [];
      for (const skuValues of allSkuData) {
        if (previousBrand) {
          if (previousBrand.brandIdentifier !== skuValues.brandIdentifier) {
            let storeColor = {
              brandIdentifier: skuValues.brandIdentifier,
              color: previousBrand.color === color1 ? color2 : color1,
            };
            updatedBrandBgColor.push(storeColor);
            previousBrand = storeColor;
          }
        } else {
          let storeColor = {
            brandIdentifier: skuValues.brandIdentifier,
            color: color1,
          };
          updatedBrandBgColor.push(storeColor);
          previousBrand = storeColor;
        }
      }
      setBrandBgColor(updatedBrandBgColor);
    }
  }, [allSkuData]);

  useEffect(() => {
    let totalUnits = 0;
    let grossValue = 0;
    let absoluteDiscount = 0;
    if (orderData.length > 0) {
      for (const orderItem of orderData) {
        totalUnits = totalUnits + orderItem.requestedVolume;
        grossValue = grossValue + orderItem.price * orderItem.requestedVolume;
        absoluteDiscount =
          absoluteDiscount +
          orderItem.price *
            orderItem.requestedVolume *
            (orderItem.onInvoiceDiscount / 100);
      }
      absoluteDiscount = Math.floor(absoluteDiscount * 100) / 100;
      let totalInvoise = grossValue - absoluteDiscount;
      setOrderSummary({
        totalUnits,
        grossValue,
        absoluteDiscount,
        totalInvoise: Math.floor(totalInvoise * 100) / 100,
      });
      if (
        editOrderData &&
        botEventData.noOfPharmacies != editOrderData.noOfPharmaciesOrder
      ) {
        if (!isFarmaciesChangeCalled) {
          setBotEventData({
            ...botEventData,
            noOfPharmacies: editOrderData.noOfPharmaciesOrder,
          });
          handlePharmaciesChange(editOrderData.noOfPharmaciesOrder);
        }
      }
    } else {
      setOrderSummary({
        totalUnits,
        grossValue,
        absoluteDiscount,
        totalInvoise: 0,
      });
    }
  }, [orderData]);

  useEffect(() => {
    window.addEventListener("message", (eventData) => {
      if (eventData?.data) {
        try {
          let parsedEventData = JSON.parse(eventData.data);
          console.log("parsedEventData::", parsedEventData);

          if (
            parsedEventData?.event_code &&
            parsedEventData?.event_code === "token-data-received"
          ) {
            setBotEventData(parsedEventData.data);
          }
        } catch (e) {}
      }
    });
  }, []);

  const getTriad = (data, type = "") => {
    if (type === "wholesaler") {
      return (
        wholesalers[selectedWholesaler].wholesalerCode +
        wholesalers[selectedWholesaler].accountCode +
        wholesalers[selectedWholesaler].legalIdCode
      );
    } else {
      return data.wholesalerCode + data.accountCode + data.legalIdCode;
    }
  };

  useEffect(() => {
    if (selectedWholesaler !== "") {
      let editOrderDate = undefined;
      let editOrderTriad = undefined;
      if (editOrderData) {
        editOrderDate = editOrderData.orderedDateString;
        editOrderTriad = getTriad(editOrderData);
      }

      const triad = getTriad(wholesalers[selectedWholesaler]);
      const triadOrderHistory = orderHistory.filter(
        (item) => getTriad(item) === triad
      );
      const findCurrentSelectedDate = triadOrderHistory.find(
        (item) => item.orderedDateString === showCalenderValue
      );
      let excludeDates = triadOrderHistory.map(
        (item) => new Date(item.orderedDateString)
      );

      if (editOrderTriad === triad) {
        excludeDates = excludeDates.filter(
          (date) => dayjs(date).format("YYYY-MM-DD") !== editOrderDate
        );
      }
      setDisabledDates(excludeDates);
      if (
        editOrderTriad &&
        editOrderTriad === triad &&
        findCurrentSelectedDate &&
        findCurrentSelectedDate?.orderedDateString === editOrderDate
      ) {
        setSelectedDate(dayjs(showCalenderValue).format("DD-MM-YYYY"));
        setOpenCalender(false);
      } else if (findCurrentSelectedDate) {
        setSelectedDate("");
        if (dateAvailble) {
          setOpenCalender(true);
        }
      }
    } else {
      setDisabledDates([]);
    }
  }, [selectedWholesaler, showCalenderValue, editOrderData, orderHistory]);

  useEffect(
    () => {
      if (orderHistory && orderHistory.length > 0 && wholesalers.length > 0) {
        let selectableWholesaler = [];
        for (let wholesaler of wholesalers) {
          const triad = getTriad(wholesaler);
          const findOrderHistory = orderHistory.find(
            (item) =>
              triad === getTriad(item) &&
              item.orderedDateString === showCalenderValue
          );
          if (findOrderHistory) {
            selectableWholesaler.push(findOrderHistory);
          }
        }
        setAvailableWholesaler(selectableWholesaler);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCalenderValue, wholesalers, orderHistory]
  );

  const checkAvailbleDates = (wholesalerIndex) => {
    const triad = getTriad(wholesalers[wholesalerIndex]);
    const checkTriad = availableWholesaler.find(
      (item) => getTriad(item) === triad
    );
    if (checkTriad) {
      return true;
    } else {
      return false;
    }
  };

  const handleWholesalerChange = (data) => {
    setSelectedWholesaler(data.value);
    setSelectedOption(data);
    setWholesalerError(false);
  };

  const handleNextDate = (todayDate) => {
    let isDateExist;
    for (let wholesaler of wholesalers) {
      const findDateExist = orderHistory.find(
        (order) =>
          order.orderedDateString === todayDate &&
          getTriad(order) === getTriad(wholesaler)
      );

      if (!findDateExist) {
        isDateExist = false;
        break;
      } else {
        isDateExist = true;
      }
    }

    if (isDateExist) {
      if (todayDate === originalSkuData[0].period.endDateString) {
        setDateAvailable(false);
        setSelectedDate("");
      } else {
        setDateAvailable(true);
        let nextDate = dayjs(todayDate).add(1, "day").format("YYYY-MM-DD");
        handleNextDate(nextDate);
      }
    } else {
      setDateAvailable(true);
      setShowCalenderValue(todayDate);
      setSelectedDate(dayjs(todayDate).format("DD-MM-YYYY"));
    }
  };

  useEffect(() => {
    if (orderHistory && orderHistory.length > 0 && wholesalers.length > 0) {
      let todayDate = dayjs().format("YYYY-MM-DD");
      handleNextDate(todayDate);
    }
  }, [orderHistory, wholesalers]);

  useEffect(() => {
    if (wholesalers && wholesalers.length === 1) {
      setSelectedWholesaler(0);
      setSelectedOption({
        label: `${wholesalers[0].wholesalerName} - ${wholesalers[0].accountCode}`,
        value: 0,
      });
    }
  }, [wholesalers]);

  const createNewOrder = () => {
    sendEventToParentIframe("new-order-clicked", {});
    setOrderData([]);
    handleFilter("");
    setEditOrderData(null);
    setIsFarmaciesChangeCalled(false);
    if (wholesalers && wholesalers.length > 1) {
      setSelectedWholesaler("");
      setSelectedOption(null);
    }
    if (orderHistory && orderHistory.length > 0 && wholesalers.length > 0) {
      let todayDate = dayjs().format("YYYY-MM-DD");
      handleNextDate(todayDate);
    }
  };

  const handleEditIcon = (orderData) => {
    setIsFarmaciesChangeCalled(false);
    setActiveTab(1);
    setEditOrderData({ ...orderData });
  };

  useEffect(() => {
    if (editOrderData) {
      const updatedEditOrderData = editOrderData.orderLineItems.map((order) => {
        return {
          loc: loc,
          orderType: "ORDER",
          internalOrderNumber: "",
          internalOrderStatus: "OPEN",
          externalOrderNumber: "",
          externalOrderDate: "",
          brandIdentifier: order.brandIdentifier,
          brand: order.brand,
          skuIdentifier: order.skuIdentifier,
          sku: order.sku,
          requestedVolume: order.units,
          invoicedVolume: "",
          onInvoiceDiscount: order.onInvoiceDiscount,
          onInvoiceFreeGoods: "",
          onInvoicePaymentTerms: "",
          offInvoiceDiscount: "",
          offInvoiceFreeGoods: "",
          phoneNumber: phoneNo,
          orderPlacedBy: phoneNo,
          accountCode: editOrderData.accountCode,
          orderDate: editOrderData.orderedDateString,
          externalOrderStatus: "",
          noOfPharmaciesOrder: editOrderData.noOfPharmaciesOrder,
          price: order.price,
        };
      });
      setShowCalenderValue(editOrderData.orderedDateString);
      setSelectedDate(
        dayjs(editOrderData.orderedDateString).format("DD-MM-YYYY")
      );
      setOrderData(updatedEditOrderData);
      handleFilter("");
      if (wholesalers.length > 0) {
        const triad = getTriad(editOrderData);
        for (let i = 0; i < wholesalers.length; i++) {
          let wholesalerTriad = getTriad(wholesalers[i]);
          if (wholesalerTriad === triad) {
            setSelectedWholesaler(i);
            setSelectedOption({
              label: `${editOrderData.wholesalerName} - ${editOrderData.accountCode}`,
              value: i,
            });
            break;
          }
        }
      }
    }
  }, [editOrderData]);

  const handleDeleteOrder = async () => {
    try {
      setIsLoading(true);
      const { status } = await axios.delete(
        `${Config.BaseURL}/authenticated/orders/${deleteOrderId}`,
        {
          headers: {
            Authorization: `Bearer ${botEventData.token}`,
          },
        }
      );
      setIsLoading(false);
      if (status === 200) {
        sendEventToParentIframe("delete-order-success", {
          orderId: deleteOrderId,
        });
        setMessageType("delete-success");
        let deleteMessage = labels.order_delete_success.replace(
          "order_id_here",
          deleteOrderId
        );
        if(!editOrderData){
          localStorage.setItem("deleteCalled","true");
        }

        setPopUpMessage(deleteMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setPopUpMessage(<span>{labels.api_error1}</span>);
      setMessageType("error");
    }
  };

  useEffect(() => {
    if (selectedDate !== "") {
      setDateError(false);
    }
  }, [selectedDate]);

  const handlePharmaciesChange = async (noOfPharmacies, focusInput = false) => {
    try {
      setIsFarmaciesChangeCalled(true);
      setIsLoading(true);
      const { data } = await axios.get(
        `${
          Config.BaseURL
        }/authenticated/v2/pharmacy-users/offers?phoneNumber=${encodeURIComponent(
          phoneNo
        )}&loc=${loc}&noOfPharmacies=${noOfPharmacies}`,
        {
          headers: {
            Authorization: `Bearer ${botEventData.token}`,
          },
        }
      );
      setIsLoading(false);
      if (data?.clubbedOffers) {
        let updatedClubbedOffers = [];
        for (let skuData of data.clubbedOffers) {
          if (skuData.skuIdentifier && skuData.skuPrice) {
            updatedClubbedOffers.push(skuData);
          }
        }

        let updatedOrderData = [];

        for (const order of orderData) {
          let findSkuData = updatedClubbedOffers.find(
            (item) => item.skuIdentifier === order.skuIdentifier
          );
          if (order.requestedVolume > findSkuData.maxVolume) {
            updatedOrderData = handleQuantityChange(
              findSkuData.maxVolume,
              findSkuData,
              true,
              updatedOrderData.length === 0 ? orderData : updatedOrderData
            );
          } else {
            updatedOrderData = handleQuantityChange(
              order.requestedVolume,
              findSkuData,
              true,
              updatedOrderData.length === 0 ? orderData : updatedOrderData
            );
          }
        }
        setOrderData(updatedOrderData);
        setAllSkuData(updatedClubbedOffers);
        setOriginalSkuData(updatedClubbedOffers);
        setInitialSkuData(data.clubbedOffers);
        setFilterValue("");
        if (focusInput) {
          inputRef.current.focus();
        }
      }
    } catch (error) {
      setIsLoading(false);
      setAllSkuData([]);
      setOriginalSkuData([]);
      setInitialSkuData([]);
      setOrderData([]);
      setWholeSalers([]);
      setEditOrderData(null);
      setFilterValue("");
    }
  };

  useEffect(() => {
    if (selectedWholesaler !== "" && closeOrder && closeOrder.length > 0) {
      const triad = getTriad(wholesalers[selectedWholesaler]);
      const closeOrderWithTriad = closeOrder.filter(
        (ele) => getTriad(ele) === triad
      );
      const closeOrderDatesWithTriad = closeOrderWithTriad.map(
        (ele) => ele.orderedDateString
      );
      setTriadCloseOrderDates(closeOrderDatesWithTriad);
    } else {
      setTriadCloseOrderDates([]);
    }
  }, [selectedWholesaler, closeOrder]);

  const highlightDates = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD");
    const openOrderDates = disabledDates.map((date) =>
      dayjs(date).format("YYYY-MM-DD")
    );
    const highlightDate = [...openOrderDates, ...triadCloseOrderDates];
    // const highlightDate = [...openOrderDates]
    return highlightDate.includes(dateString) ? "highlight-date" : "";
  };

  useEffect(() => {
    if (localStorage.getItem("bot-data")) {
      setActiveTab(2);
      if(localStorage.getItem("deleteCalled")){
        localStorage.removeItem("deleteCalled");
      }else{
        setIsReloaded(true);
      }
      const botData = JSON.parse(localStorage.getItem("bot-data"));
      setBotEventData(botData);
      localStorage.removeItem("bot-data");
    }
  }, []);

  const downloadOrderHistory = async () => {
    if (closeOrder.length > 0 || orderHistory.length > 0) {
      const period = `period:cic:${originalSkuData[0]?.period?.period}`;
      const phone = `phoneNumber:cic:${phoneNo}`;
      const locName = `loc:cic:${loc}`;
      axios({
        url: `${Config.BaseURL}/authenticated/orders/export/csv`,
        method: "post",
        headers: { Authorization: `Bearer ${botEventData.token}` },
        responseType: "blob",
        data: {
          filters: [period, "AND", phone, "AND", locName],
        },
      })
        .then((response) => {
          downloadExportFile(response, "xlsx", "download_history");
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="ui-container">
      <Tooltip
        id="custom-tooltip"
        place="bottom"
        effect="solid"
        style={{ background: "black", opacity: 1, padding: "5px 10px" }}
      />
      {showPopUp && (
        <div className="pop-up-message-container" style={{ zIndex: 5 }}>
          <div
            className="pop-up-message-child"
            style={{ width: messageType === "success" ? "350px" : "300px" }}
          >
            <div
              className="pop-message-text"
              style={{
                textAlign: messageType === "success" ? "left" : "center",
              }}
            >
              {messageType === "error" && (
                <img src={ErrorIcon} alt="error-icon" />
              )}
              {messageType === "success" && (
                <img src={SuccessIcon} alt="success-icon" />
              )}
              {messageType === "delete-success" && (
                <img src={SuccessIcon} alt="success-icon" />
              )}
              {popUpMessage}
              {messageType === "success" && (
                <>
                  {thanksMessage}
                </>
              )}
            </div>
            {(messageType === "success" ||
              messageType === "delete-success") && (
              <button
                className="pop-up-message-button"
                style={{ borderRadius: "4px" }}
                onClick={() => {
                  localStorage.setItem(
                    "bot-data",
                    JSON.stringify(botEventData)
                  );
                  window.location.reload();
                }}
              >
                {labels.ok_button}
              </button>
            )}
            {messageType !== "delete-success" && messageType !== "success" && (
              <div
                className="pop-up-button-container"
                style={{
                  flexDirection:
                    messageType === "cancelMessage" ? "column" : "row",
                }}
              >
                <button
                  className="pop-up-message-button"
                  style={{ borderRadius: "4px" }}
                  onClick={() => {
                    if (messageType === "cancelMessage") {
                      cancelForm();
                    } else if (messageType === "confirmMessage") {
                      handleSubmit();
                    } else {
                      if (messageType === "delete-order") {
                        handleDeleteOrder();
                      } else {
                        setPopUpMessage("");
                        setShowPopUp(false);
                      }
                    }
                  }}
                >
                  {messageType === "error"
                    ? labels.go_back
                    : messageType === "cancelMessage"
                    ? cancelButtonsMessage?.yesButton
                    : labels.confirm_pop_up_button}
                </button>
                {messageType !== "error" && (
                  <button
                    className="pop-up-message-button"
                    style={{ borderRadius: "4px" }}
                    onClick={() => {
                      setPopUpMessage("");
                      setShowPopUp(false);
                    }}
                  >
                    {messageType === "cancelMessage"
                      ? cancelButtonsMessage?.noButton
                      : labels.go_back}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {botEventData?.token && (
        <div
          className="child-container"
          style={{ padding: activeTab === 2 ? "0px" : "5px 25px 8px 25px" }}
        >
          <div
            style={{
              background: "#f0f0f0",
              zIndex: 3,
              padding: activeTab === 2 ? "5px 25px 8px 25px" : "0px",
            }}
          >
            <div style={{ display: "flex" }}>
              <span
                style={{ flex: 1, display: "flex", alignItems: "end" }}
                className={`order-tab ${
                  activeTab === 1 ? "active-tab" : "inactive-tab"
                }`}
                onClick={() => {
                  if(activeTab !== 1 && isReloaded && !editOrderData){
                    sendEventToParentIframe("new-order-clicked", {});
                  }else if (activeTab !== 1 && !editOrderData && orderFlow==="edit") {
                    sendEventToParentIframe("new-order-clicked", {});
                  }else{
                    setActiveTab(1);
                  }
                }}
              >
                {labels.order_details.toUpperCase()}
              </span>
              <span
                style={{ flex: 1, display: "flex", alignItems: "end" }}
                className={`order-tab ${
                  activeTab === 2 ? "active-tab" : "inactive-tab"
                }`}
                onClick={() => {
                  setActiveTab(2);
                  if (activeTab !== 2 && sendHistoryTabEvent) {
                    sendEventToParentIframe("order-history-tab", {});
                    setSendHistoryTabEvent(false);
                  }
                }}
              >
                {labels.period_orders.toUpperCase()}
              </span>
            </div>
            {activeTab === 2 && (
              <div
                style={{
                  paddingBottom: "8px",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <button
                    className="font12 new-order-button"
                    onClick={() => {
                      sendEventToParentIframe("new-order-clicked", {});
                      // setActiveTab(1);
                      // createNewOrder();
                    }}
                  >
                    {labels.new_order_button}
                  </button>
                </div>
                <button
                  data-tooltip-id="custom-tooltip2"
                  data-tooltip-content={labels.download_tooltip}
                  className="font12 primary-button"
                  style={{
                    width: "50px",
                    height: "27px",
                    marginTop: "5px",
                    fontSize: "14px",
                    position: "absolute",
                    marginRight: "3px",
                    right: 0,
                  }}
                  onClick={downloadOrderHistory}
                >
                  <i className="fa fa-download" aria-hidden="true" />
                </button>
                <Tooltip
                  id="custom-tooltip2"
                  place="bottom"
                  effect="solid"
                  style={{
                    background: "black",
                    opacity: 1,
                    padding: "5px 10px",
                  }}
                />
              </div>
            )}
          </div>
          {isLoading && <PageLoader />}
          {activeTab === 1 ? (
            <>
              <div className="title-container">
                {editOrderData ? (
                  <span style={{ fontSize: "14px" }}>
                    <span
                      className="orange-color"
                      style={{ fontWeight: "500px" }}
                    >
                      {labels.editing_order}
                    </span>{" "}
                    {editOrderData.orderId}
                  </span>
                ) : (
                  <span style={{ fontSize: "14px" }}>
                    <span
                      className="orange-color"
                      style={{ fontWeight: "500px" }}
                    >
                      {labels.placing_new_order.toUpperCase()}
                    </span>
                  </span>
                )}
                {type === formType.type1 && (
                  <span
                    className="noOfPharmacies"
                    style={{
                      flex: 1,
                      textAlign: "right",
                      whiteSpace: "nowrap",
                      paddingLeft: "10px",
                    }}
                  >
                    {labels.noOfPhar}
                    {userType?.toLowerCase() === "group" ? (
                      <span style={{ fontSize: "18px" }}>
                        <input
                          ref={inputRef}
                          type="number"
                          disabled={isLoading}
                          className="no-of-pharmacies-input"
                          onChange={(e) => {
                            const regex = /^-?\d+$/;
                            if (e.target.value > 100) {
                              return;
                            } else if (
                              e.target.value === "0" ||
                              e.target.value < 0
                            ) {
                              return;
                            } else if (e.target.value === "") {
                              setBotEventData({
                                ...botEventData,
                                noOfPharmacies: "0",
                              });
                              handlePharmaciesChange(1, true);
                            } else if (regex.test(e.target.value)) {
                              setBotEventData({
                                ...botEventData,
                                noOfPharmacies: e.target.value.toString(),
                              });
                              handlePharmaciesChange(e.target.value, true);
                            }
                          }}
                          value={
                            botEventData.noOfPharmacies === "0"
                              ? ""
                              : botEventData.noOfPharmacies
                          }
                          style={{
                            width:
                              botEventData?.noOfPharmacies == 100
                                ? "32px"
                                : botEventData?.noOfPharmacies > 9
                                ? "20px"
                                : "13px",
                          }}
                        />
                      </span>
                    ) : (
                      <span style={{ fontSize: "18px", marginLeft: "4px" }}>
                        {botEventData.noOfPharmacies}
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div
                style={{
                  background: "white",
                  padding: "13px 15px",
                  borderRadius: "7px",
                  boxShadow: "0px 7px 5px -6px rgba(0, 0, 0, 0.15)",
                }}
              >
                <div className="form-container">
                  <div className="left-side-div">
                    <div>
                      <Select
                        value={selectedOption}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            background: "#fff",
                            borderColor: "grey",
                            minHeight: "25px",
                            height: "25px",
                            borderRadius: "2px",
                            fontSize: "13px",
                          }),

                          valueContainer: (provided, state) => ({
                            ...provided,
                            height: "25px",
                            padding: "0 6px",
                          }),

                          input: (provided, state) => ({
                            ...provided,
                            margin: "0px",
                          }),
                          indicatorSeparator: (state) => ({
                            display: "none",
                          }),
                          indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: "25px",
                          }),
                          option: (provided, state) => {
                            const isGreyOut = checkAvailbleDates(
                              state.data.value
                            );
                            return {
                              ...provided,
                              height: 25,
                              color: state.isSelected
                                ? "white"
                                : isGreyOut
                                ? "grey"
                                : "black",
                              backgroundColor: state.isSelected
                                ? "#f07a19"
                                : "white",
                              "&:hover": {
                                background: state.isSelected
                                  ? "#f07a19"
                                  : "#fae5d6",
                                color: state.isSelected
                                  ? "white"
                                  : isGreyOut
                                  ? "grey"
                                  : "black",
                              },
                              display: "flex",
                              alignItems: "center",
                              fontSize: "13px",
                            };
                          },
                        }}
                        placeholder={labels.wholesaler_placeholder}
                        onChange={(data) => {
                          handleWholesalerChange(data);
                        }}
                        options={options}
                      />
                      {wholesalerError && !editOrderData && (
                        <span className="error-message">
                          <i>{labels.wholesaler_error}</i>
                        </span>
                      )}
                      {dateError && <div style={{ height: "14px" }} />}
                    </div>

                    <input
                      value={filterValue}
                      id="filter-input"
                      onChange={(e) => {
                        handleFilter(e.target.value);
                      }}
                      className="sku-filter-input"
                      placeholder={labels.filter_placeholder}
                    />
                  </div>
                  <div
                    className="right-side-div"
                    style={{ gap: !dateError ? "10px" : "0px" }}
                  >
                    <div className="custom-calendar-icon">
                      <DatePicker
                        dayClassName={highlightDates}
                        placeholder={labels.date_placeholder}
                        excludeDates={disabledDates}
                        placeholderText={labels.date_placeholder}
                        onInputClick={() => {
                          setOpenCalender(true);
                        }}
                        onClickOutside={() => {
                          setOpenCalender(false);
                        }}
                        open={openCalender}
                        selected={
                          selectedDate === "" ? "" : new Date(showCalenderValue)
                        }
                        autoComplete="off"
                        minDate={new Date(todayDate)}
                        className="date-picker-form"
                        maxDate={
                          originalSkuData[0]?.period?.endDateString
                            ? new Date(originalSkuData[0].period.endDateString)
                            : new Date(todayDate)
                        }
                        value={selectedDate}
                        dateFormat="dd/MM/YYYY"
                        onChange={(date) => {
                          const dayjsDate = dayjs(date);
                          setShowCalenderValue(dayjsDate.format("YYYY-MM-DD"));
                          setSelectedDate(dayjsDate.format("DD-MM-YYYY"));
                          setOpenCalender(false);
                        }}
                      />

                      {dateError && (
                        <span
                          className="error-message"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <i>{labels.date_error}</i>
                        </span>
                      )}
                    </div>
                    {wholesalerError && !editOrderData && (
                      <div style={{ height: "14px" }} />
                    )}
                    <button
                      className="sku-clear-button primary-button"
                      onClick={clearFilter}
                    >
                      {labels.cleara_button}
                    </button>
                  </div>
                </div>
                {!dateAvailble && !editOrderData && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "3px",
                    }}
                  >
                    {labels.no_date_available}
                  </span>
                )}
              </div>

              <div
                className="sku-table-container"
                style={{
                  borderColor: borderColor,
                }}
              >
                <table className="gsk-font">
                  <thead style={{ position: "sticky", top: "0px" }}>
                    <tr>
                      {type === formType.type2 && (
                        <th
                          className="font13 sku-name"
                          style={{
                            borderColor: borderColor,
                            textAlign: "center",
                            height: "30px",
                          }}
                        >
                          {labels.sku_th}
                        </th>
                      )}
                      <th
                        className="font13 sku-name"
                        style={{
                          borderColor: borderColor,
                          width: type === formType.type2 ? "100%" : "auto",
                        }}
                      >
                        {labels.products_th}
                      </th>
                      {type === formType.type1 && (
                        <th
                          className="font13 discount-ptw center-text"
                          style={{
                            borderColor: borderColor,
                          }}
                        >
                          <span
                            style={{ display: "block", textAlign: "center" }}
                          >
                            {labels.table_discount}
                          </span>
                          <span
                            style={{ display: "block", textAlign: "center" }}
                          >
                            {labels.price}
                          </span>
                        </th>
                      )}
                      <th
                        className="font13 requested-volume"
                        style={{
                          borderColor: borderColor,
                        }}
                      >
                        {labels.units_th}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSkuData.length > 0 &&
                      allSkuData.map((skuData, index) => {
                        return (
                          <tr
                            key={index}
                            className="font12"
                            style={{
                              background: getBrandBackgroundColor(
                                skuData,
                                false
                              ),
                            }}
                          >
                            {type === formType.type2 && (
                              <td
                                className="font500 center-text"
                                style={{
                                  borderColor: borderColor,
                                  borderBottom: "none",
                                  borderLeft: "none",
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                {skuData?.skuIdentifier}
                              </td>
                            )}
                            <td
                              className="sku-name-column"
                              style={{
                                borderColor: borderColor,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding:
                                    type === formType.type2 ? "0px 10px" : "",
                                }}
                              >
                                <span className="font500">{allowSkuIdentifierSearch && skuData.ean + " - "}{skuData.sku}</span>
                                {type === formType.type2 ? (
                                  getFTLink(skuData?.skuIdentifier)
                                ) : (
                                  getIrecmLink(skuData?.skuIdentifier)
                                )}
                              </div>
                              {type === formType.type1 && (
                                <table className="gsk-font discount-range-table">
                                  <tbody>
                                    <tr style={{ display: "flex" }}>
                                      <td
                                        className="center-text font500 offer-column discount-table-td"
                                        style={{
                                          border: `1px solid ${borderColor}`,
                                          width: "72px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {labels.discount}
                                      </td>
                                      {skuData.onInvoiceDiscountRanges
                                        .slice(
                                          0,
                                          skuData.onInvoiceDiscountRanges
                                            .length - 1
                                        )
                                        .map((discountData, index) => {
                                          return (
                                            <td
                                              key={index}
                                              style={{
                                                border: `1px solid ${borderColor}`,
                                                flex: 1,
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                lineHeight: "13px",
                                              }}
                                              className="center-text font500 no-left-border discount-table-td"
                                            >
                                              <span>
                                                <span className="orange-color">
                                                  {parseInt
                                                    (skuData
                                                      .onInvoiceDiscountRanges[
                                                      index + 1
                                                    ].discount)
                                                  }
                                                  %
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {discountData.label}{" "}
                                                  <span
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    u
                                                  </span>
                                                </span>
                                              </span>
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  </tbody>
                                </table>
                              )}
                            </td>
                            {type === formType.type1 && (
                              <td
                                className="font500 center-text"
                                style={{
                                  background: getBrandBackgroundColor(
                                    skuData,
                                    true
                                  ),
                                  borderColor: borderColor,
                                  borderBottom: "none",
                                }}
                              >
                                <span
                                  className="orange-color block-display"
                                  style={{ fontSize: "18px" }}
                                >
                                  {getDiscountPercent(skuData)}
                                </span>
                                {labels.currency} {skuData.skuPrice || 0}
                              </td>
                            )}
                            <td
                              className="input-column"
                              style={{
                                borderColor: borderColor,
                              }}
                            >
                              <input
                                tabIndex={index + 1}
                                value={getRequestedVolume(skuData)}
                                min={1}
                                required
                                max={10}
                                className="full-width"
                                onChange={(e) => {
                                  handleQuantityChange(e.target.value, skuData);
                                }}
                                style={{
                                  textAlign: "center",
                                  fontSize: "15px",
                                  width: "80px",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {allSkuData.length === 0 && !isLoading && (
                  <div
                    className="gsk-font text-center"
                    style={{ marginTop: "16vh" }}
                  >
                    {labels.filter_error}
                  </div>
                )}
              </div>

              <table className="summary-table">
                <tbody>
                  <tr>
                    <td
                      className="summary-left-side"
                      style={{
                        borderColor: borderColor,
                      }}
                    >
                      <span className="summary-table-item">
                        {labels.total_units}
                      </span>
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.total_value}
                        </span>
                      )}
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.total_discount}
                        </span>
                      )}
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.final_value}
                        </span>
                      )}
                    </td>
                    <td
                      className="summary-right-side"
                      style={{
                        borderColor: borderColor,
                        width:
                          type === formType.type2 && allSkuData.length > 9
                            ? "95px"
                            : type === formType.type2
                            ? "80px"
                            : "164px",
                      }}
                    >
                      <span className="summary-table-item">
                        {orderSummary.totalUnits}
                      </span>
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.currency}{" "}
                          {orderSummary.grossValue?.toFixed(2)}
                        </span>
                      )}
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.currency}{" "}
                          {orderSummary.absoluteDiscount?.toFixed(2)}
                        </span>
                      )}
                      {type !== formType.type2 && (
                        <span className="summary-table-item">
                          {labels.currency}{" "}
                          {orderSummary.totalInvoise?.toFixed(2)}
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="button-container">
                <button
                  className="font12 confirm-form-button"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 5px",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={handleConfirmPopUp}
                >
                  {editOrderData ? labels.edit_button : labels.confirm_button}
                </button>
                <button
                  className="font12 primary-button"
                  style={{
                    border: "1px solid white",
                    height: "28px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 5px",
                    fontWeight: "bold",
                    padding: "3px 20px",
                  }}
                  onClick={handleCancelPopUp}
                >
                  {labels.cancel_button}
                </button>
               {editOrderData && <button
                  className="font12 primary-button"
                  style={{
                    border: "1px solid white",
                    height: "28px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 5px",
                    fontWeight: "bold",
                    padding: "3px 20px",
                  }}
                  onClick={()=>{
                    setDeleteOrderId(editOrderData.orderId);
                    setMessageType("delete-order");
                    setShowPopUp(true);
                    setPopUpMessage(labels?.deleteOrderMessage);
                  }}
                >
                  <img
                      src={WhiteDeleteIcon}
                      alt="delete-icon"
                  />
                </button>}
              </div>
            </>
          ) : (
            <OrderHistory
              sendEventToParentIframe={sendEventToParentIframe}
              setActiveTab={setActiveTab}
              openOrders={orderHistory}
              closeOrders={closeOrder}
              createNewOrder={createNewOrder}
              handleEditIcon={handleEditIcon}
              setMessageType={setMessageType}
              setPopUpMessage={setPopUpMessage}
              setShowPopUp={setShowPopUp}
              setDeleteOrderId={setDeleteOrderId}
              labels={labels}
              isLoading={isLoading}
              formType={type}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LocCheckout;
