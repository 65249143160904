import React, { useState } from "react";
import Edit from "./SVGIcon/edit.svg";
import DeleteIcon from "./SVGIcon/deleteIcon.svg";
import DownArrow from "./SVGIcon/downArrow.svg";
import WhiteDownArrow from "./SVGIcon/whiteDownArrow.svg";
import dayjs from "dayjs";

const formTypes = {
  type1: "type1",
  type2: "type2",
};

const OrderCard = ({
  type,
  orderData,
  labels,
  setDeleteOrderId,
  handleEditIcon,
  setMessageType,
  setShowPopUp,
  setPopUpMessage,
  sendEventToParentIframe,
  formType,
}) => {
  const [open, setOpen] = useState(false);

  const getStatusColor = (status, type = "", cardType) => {
    if(cardType==="close"){
      return "rgb(198, 196, 196)";
    }
    if (
      status === "IN PROCESS" ||
      status === "EM PROCESSO" ||
      status === "EN PROCESO"
    ) {
      return "rgb(0,0,0)";
    }
    if (status === "CONDITIONAL" || status === "CONDICIONAL") {
      if (type === "font") {
        return "rgb(191,144,0)";
      } else {
        return "rgb(255,192,0)";
      }
    }

    if (
      status === "AWAITING DATE" ||
      status === "EN ESPERA DE FECHA" ||
      status === "DATA PENDENTE"
    ) {
      return "rgb(68,114,196)";
    }

    if (
      status === "INVOICED" ||
      status === "FACTURADO"
    ) {
      return "rgb(0,176,80)";
    }

    if (
      status === "CANCELLED" ||
      status === "CANELADO" ||
      status === "CANCELADA"
    ) {
      return "rgb(255,60,60)";
    }

    if (status === "SENT" || status === "ENVIADO" || status === "ENVIADA") {
      return "rgb(0,153,138)";
    }

    if (status === "EXPIRED" || status === "EXPIRADA") {
      return "rgb(153,102,255)";
    }

    if (
      status === "DRAFT" ||
      status === "NOT CONFIRMED" ||
      status === "NÃO CONFIRMADO" ||
      status === "NO CONFIRMADO"
    ) {
      return "rgb(166,166,166)";

    }
    return "black";
  };

  return (
    <div
      className="open-order-card gsk-font"
      style={{
        background: type === "open" ? "white" : "#3f3f3f",
        color: type === "open" ? "black" : "#959595",
        border: type === "open" ? "1px solid grey" : "",
      }}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div style={{ padding: "6px 0px", marginTop: "10px" }}>
        <div
          className="order-history-mark"
          style={{ background: getStatusColor(orderData?.status,"","bar") }}
        />
      </div>
      <div className="flex1" style={{ padding: "4px", marginLeft: "8px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {orderData?.wholesalerName} - {orderData?.accountCode}
                <span
                  style={{
                    fontSize: "13px",
                    color: type === "open" ? "#151515" : "#959595",
                    fontWeight: "normal",
                    paddingRight: "10px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {labels?.date_history}:
                  </span>{" "}
                  {dayjs(orderData?.orderedDateString).format("MMM D, YYYY")}
                </span>
              </div>
              <div
                style={{
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: type === "open" ? "#151515" : "#959595",
                }}
              >
                {labels?.order_no_history}: {orderData?.orderId}
              </div>
            </div>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                color: getStatusColor(orderData?.status,"font" ,type),
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {orderData?.status}{" "}
              <span style={{ display: "flex", alignContent: "center" }}>
                {type === "open" && (
                  <div style={{ display: "flex" }}>
                    <div
                      data-tooltip-id="custom-tooltip"
                      data-tooltip-content={labels.edit_tooltip}
                      style={{
                        width: "24px",
                        marginLeft: "5px",
                        height: "23px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleEditIcon(orderData);
                        sendEventToParentIframe("edit-order-clicked", {
                          orderId: orderData.orderId,
                        });
                      }}
                    >
                      <img
                        src={Edit}
                        className="icon-click-effect"
                        alt="edit-icon"
                      />
                    </div>
                    <div
                      data-tooltip-id="custom-tooltip"
                      data-tooltip-content={labels.delete_tooltip}
                      style={{
                        width: "25px",
                        marginLeft: "5px",
                        height: "23px",
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteOrderId(orderData.orderId);
                        setMessageType("delete-order");
                        setShowPopUp(true);
                        setPopUpMessage(labels?.deleteOrderMessage);
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        className="icon-click-effect"
                        alt="delete-icon"
                      />
                    </div>
                  </div>
                )}
                <img
                  data-tooltip-id="custom-tooltip"
                  data-tooltip-content={labels.more_details_tooltip}
                  src={type === "open" ? DownArrow : WhiteDownArrow}
                  alt="delete-icon"
                  style={{
                    cursor: "pointer",
                    transform: !open ? "rotate(0deg)" : "rotate(180deg)",
                    paddingRight: !open ? "4px" : "0px",
                    paddingLeft: open ? "4px" : "0px",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        {open && (
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14.5px",
                padding: "8px 0px",
              }}
            >
              {labels?.order_details_history}
            </div>
            <div>
              <div style={{ maxHeight: "140px", overflow: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "3px",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  <span
                    className="orange-bold"
                    style={{ flex: 1.2, display: "flex", alignItems: "end" }}
                  >
                    {labels?.sku_th_history}
                  </span>
                  <span
                    className="orange-bold"
                    style={{
                      flex: 0.6,
                      paddingLeft: "2px",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    {labels?.units_th_history}
                  </span>
                  {formType === formTypes.type1 && (
                    <span
                      className="orange-bold"
                      style={{
                        paddingLeft: "2px",
                        display: "flex",
                        alignItems: "end",
                        flex: 0.6,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {labels?.on_invoice_discount_history}
                    </span>
                  )}
                  {formType === formTypes.type1 && (
                    <span
                      className="orange-bold"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "2px",
                        flex: 0.6,
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      {labels?.price}
                    </span>
                  )}
                </div>
                <div>
                  {orderData?.orderLineItems &&
                    orderData?.orderLineItems?.map((orderItem, index) => {
                      return (
                        <div
                          style={{
                            marginTop: "3px",
                            borderTop: "1px solid #e6e6e6",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                          key={index}
                        >
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                flex: 1.2,
                              }}
                            >
                              {orderItem.sku}
                            </span>
                            <span
                              style={{
                                flex: 0.6,
                                fontSize: "14px",
                                paddingLeft: "2px",
                              }}
                            >
                              {orderItem?.units}
                            </span>
                            {formType === formTypes.type1 && (
                              <span
                                style={{ fontSize: "14px", paddingLeft: "2px", flex: 0.6 }}
                              >
                                {orderItem?.onInvoiceDiscount}%
                              </span>
                            )}
                            {formType === formTypes.type1 && (
                              <span
                                style={{
                                  fontSize: "14px",
                                  paddingRight: "10px",
                                  paddingLeft: "2px",
                                  whiteSpace: "nowrap",
                                  flex: 0.6,
                                }}
                              >
                                {labels?.currency} {orderItem?.price}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
