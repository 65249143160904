import { combineReducers } from "redux";
import { storeConstants } from "../../library/common/constants/StoreConstants";
import AuthReducer from "../../library/common/reducers/AuthReducer";
import LanguageReducer from "../../library/common/reducers/LanguageReducer";
import UserManagementReducer from "../../modules/UserManagement/UserManagementReducer";
import FilterReducer from "../../library/common/reducers/FilterReducer";
import WholesalersReducer from "../../modules/Wholesalers/WholesalersReducer";
import AccountsReducer from "../../modules/Accounts/AccountsReducer";
import PharmacyUsersReducer from "../../modules/PharmacyUsers/PharmacyUsersReducer";
import ClientPosAccountReducer from "../../modules/ClientPosAccount/ClientPosAccountReducer";
import SegmentationReducer from "../../modules/Segmentation/SegmentationReducer";
import PredefinedOfferReducer from "../../modules/PredefinedOffer/PredefinedOfferReducer";
import ManualOfferReducer from "../../modules/ManualOffer/ManualOfferReducer";
import BonusOfferReducer from "../../modules/BonusOffer/BonusOfferReducer";
import CommercialConditionReducer from "../../modules/CommercialCondition/CommercialConditionReducer";
import OfferPeriodReducer from "../../modules/OfferPeriod/OfferPeriodReducer";
import OrderReducer from "../../modules/Order/OrderReducer";
import StandardSegmentOfferReducer from "../../modules/StandardSegmentOffer/StandardSegmentOfferReducer";
import ValidOfferReducer from "../../modules/ValidOffer/ValidOfferReducer";
import ExtWholesalerMappingReducer from "../../modules/ExtarnalWholesalerMapping/ExtarnalWholesalerMappingReducer";
import FeedbackReducer from "../../modules/Feedback/FeedbackReducer";
import EventConfigurationReducer from "../../modules/EventConfiguration/EventConfigurationReducer";
import EventHistoryReducer from "../../modules/EventHistory/EventHistoryReducer";
import LocOrderReducer from "../../modules/LocOrder/LocOrderReducer";
import TempOrderReducer from "../../modules/TempOrder/TempOrderReducer";
import LinkTableReducer from "../../modules/LinkTable/LinkTableReducer";

const appReducer = combineReducers({
  authReducer: AuthReducer,
  language: LanguageReducer,
  user: UserManagementReducer,
  Wholesalers: WholesalersReducer,
  PharmacyUsers: PharmacyUsersReducer,
  ClientPosAccount: ClientPosAccountReducer,
  Segmentation: SegmentationReducer,
  PredefinedOffer: PredefinedOfferReducer,
  ManualOffer: ManualOfferReducer,
  BonusOffer: BonusOfferReducer,
  CommercialCondition: CommercialConditionReducer,
  Accounts: AccountsReducer,
  OfferPeriod: OfferPeriodReducer,
  Order: OrderReducer,
  StandardSegmentOffer: StandardSegmentOfferReducer,
  ValidOffer: ValidOfferReducer,
  filter: FilterReducer,
  ExtWholesalerMapping: ExtWholesalerMappingReducer,
  Feedback: FeedbackReducer,
  EventConfiguration: EventConfigurationReducer,
  EventHistory: EventHistoryReducer,
  LocOrder: LocOrderReducer,
  TempOrder: TempOrderReducer,
  LinKTable: LinkTableReducer
});

const mainReducer = (state, action) => {
  if (action.type === storeConstants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default mainReducer;
